import {
  AUTH_ERROR,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD,
  CLEAR_MESSAGES,
  CREATE_NEW_GROUP_SUCCESS,
  CREATE_NEW_GROUP,
  CREATE_NEW_PRODUCT_SUCCESS,
  CREATE_NEW_PRODUCT,
  DEACTIVATE_PRODUCT_SUCCESS,
  DEACTIVATE_PRODUCT,
  EXTERNAL_ACCOUNTS_SUCCESS,
  EXTERNAL_ACCOUNTS,
  GET_PRODUCTS_LIST_SUCCESS,
  LOGIN_USER_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_RECOVERY,
  REGISTER_USER_ERROR,
  REGISTER_USER_SUCCESS,
  REGISTER_USER,
  RELOAD_PAGE_AUTH_SUCCESS,
  RELOAD_PAGE_AUTH,
  REQUEST_STRIPE_PAYMENT_ERROR,
  REQUEST_STRIPE_PAYMENT_SUCCESS,
  REQUEST_STRIPE_PAYMENT,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD,
  START_BROADCAST_RESPONSE,
  START_BROADCAST,
  STRIPE_CONFIRM_SETUP_SUCCESS,
  STRIPE_CONFIRM_SETUP,
  STRIPE_VALIDATION_PENDING,
  SUCCESS_MESSAGE,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE,
  UPDATE_MERCHANT_SUCCESS,
  UPDATE_MERCHANT,
  UPDATE_PICTURE_SUCCESS,
  UPDATE_PICTURE,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_USER_SUCCESS,
  UPDATE_USER,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL,
} from './constants';

const INIT_STATE = {
  loading: false,
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      delete state.success;
      delete state.error;
      return { ...state, loading: true, initializing: true };
    case LOGIN_USER_SUCCESS: {
      delete state.error;
      delete state.initializing;
      const { user, groups, reports } = action.payload;
      return {
        ...state,
        user,
        groups,
        reports,
        loading: false,
      };
    }

    case STRIPE_VALIDATION_PENDING: {
      const { pendingVerification, errors } = action.payload;
      return { ...state, pendingVerification, errors };
    }

    case REGISTER_USER:
      return { ...state, loading: true };
    case REGISTER_USER_SUCCESS:
      delete state.error;
      return { ...state, success: action.payload, loading: false };
    case REGISTER_USER_ERROR: {
      const { message, errors } = action.payload;
      return { ...state, loading: false, error: message, errors };
    }

    case VALIDATE_EMAIL:
      return { ...state, loading: true };
    case VALIDATE_EMAIL_SUCCESS:
      delete state.error;
      return { ...state, success: action.payload, loading: false };

    case LOGOUT_USER:
      return { ...state, loading: true };

    case RELOAD_PAGE_AUTH:
      return { ...state, loading: true, initializing: true };
    case RELOAD_PAGE_AUTH_SUCCESS: {
      delete state.error;
      delete state.initializing;
      const { user, groups, reports } = action.payload;
      return {
        ...state,
        user,
        groups,
        reports,
        loading: false,
      };
    }

    case STRIPE_CONFIRM_SETUP:
      return {
        ...state,
        loading: true,
      };
    case STRIPE_CONFIRM_SETUP_SUCCESS:
      delete state.initializing;
      const { redirect_status } = action.payload;
      return {
        ...state,
        redirect_status,
      };

    case REQUEST_STRIPE_PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_STRIPE_PAYMENT_SUCCESS:
      delete state.redirect_status;
      const { services, message } = action.payload;
      return {
        ...state,
        loading: false,
        success: message,
        user: { ...state.user, services },
      };
    case REQUEST_STRIPE_PAYMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        services: action.payload.services,
      };

    case PASSWORD_RECOVERY:
      return { ...state, loading: true };
    case PASSWORD_RECOVERY_SUCCESS:
      delete state.error;
      return {
        ...state,
        success: action.payload,
        loading: false,
      };

    case SET_NEW_PASSWORD:
      return { ...state, loading: true };
    case SET_NEW_PASSWORD_SUCCESS:
      delete state.error;
      return {
        ...state,
        success: action.payload,
        loading: false,
      };

    case CHANGE_PASSWORD:
      return { ...state, loading: true };
    case CHANGE_PASSWORD_SUCCESS:
      delete state.error;
      return {
        ...state,
        success: action.payload,
        loading: false,
      };

    case UPDATE_PICTURE:
      return { ...state, loading: true };
    case UPDATE_PICTURE_SUCCESS:
      delete state.error;
      const { path, type } = action.payload;
      const newUser =
        type === 'user'
          ? { ...state.user, profilePicture: path }
          : { ...state.user, merchantProfilePicture: path };
      return {
        ...state,
        user: newUser,
        loading: false,
      };

    case UPDATE_FILE:
      return { ...state, loading: true };
    case UPDATE_FILE_SUCCESS: {
      const { success } = action.payload;
      return {
        ...state,
        success,
        loading: false,
      };
    }

    case UPDATE_USER:
      return { ...state, loading: true };
    case UPDATE_USER_SUCCESS:
      delete state.error;
      return {
        ...state,
        success: action.payload.success,
        user: action.payload.user,
        loading: false,
      };

    case UPDATE_MERCHANT:
      return { ...state, loading: true };
    case UPDATE_MERCHANT_SUCCESS:
      delete state.error;
      return {
        ...state,
        success: action.payload.success,
        user: action.payload.merchant,
        loading: false,
      };

    case EXTERNAL_ACCOUNTS:
      return { ...state, loading: true };
    case EXTERNAL_ACCOUNTS_SUCCESS: {
      const { success, user } = action.payload;
      return {
        ...state,
        success,
        user,
        loading: false,
      };
    }

    case CREATE_NEW_GROUP:
      return { ...state, loading: true };
    case CREATE_NEW_GROUP_SUCCESS:
      delete state.error;
      const { success, groups } = action.payload;
      return {
        ...state,
        loading: false,
        success,
        groups,
      };

    case CREATE_NEW_PRODUCT:
      return { ...state, loading: true };
    case CREATE_NEW_PRODUCT_SUCCESS:
      delete state.error;
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case UPDATE_PRODUCT:
      return { ...state, loading: true };
    case UPDATE_PRODUCT_SUCCESS:
      delete state.error;
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case START_BROADCAST:
      return { ...state, loading: true };
    case START_BROADCAST_RESPONSE:
      return { ...state, loading: false, success: action.payload };

    case SUCCESS_MESSAGE:
      return { ...state, loading: false, success: action.payload };
    case CLEAR_MESSAGES:
      delete state.setup_intent;
      delete state.setup_intent_client_secret;
      delete state.redirect_status;
      delete state.error;
      delete state.success;
      return { ...state, loading: false };
    case AUTH_ERROR:
      delete state.success;
      delete state.setup_intent;
      delete state.setup_intent_client_secret;
      delete state.redirect_status;
      delete state.initializing;
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};

export default Auth;
