import React from 'react';
import PropTypes from 'prop-types';
import SidebarNavItem from './SidebarNavItem';
import moment from 'moment';
import { Store } from '../../../flux';
import { Nav, NavItem, NavLink, Collapse } from 'shards-react';
import { Button } from 'reactstrap';
import { groupServicesByParent } from '../../../helpers/customFunctions';
import { connect } from 'react-redux';

const SidebarNavItems = ({ user, loading, success, error }) => {
  const [navItems, setNavItems] = React.useState(Store.getSidebarItems());
  const [services, setServices] = React.useState([]);
  const [collapse, setCollapse] = React.useState(true);

  React.useEffect(() => {
    Store.addChangeListener(onChange);
    return () => {
      Store.removeChangeListener(onChange);
    };
  }, []);

  React.useEffect(() => {
    if (user) {
      const groups = groupServicesByParent(user.services);
      setServices(groups);
    }
  }, [user]);

  const onChange = () => {
    setNavItems(Store.getSidebarItems());
  };

  return (
    <div className='nav-wrapper'>
      <Nav className='nav--no-borders flex-column'>
        {navItems
          .filter((item) => item.to !== '/logout')
          .map((item, key) => (
            <SidebarNavItem
              loading={loading}
              success={success}
              error={error}
              key={key}
              item={item}
              isLocalRoute={item.isLocalRoute}
            />
          ))}
        {services &&
          services.map((group, key) => (
            <div key={key}>
              {/* <NavItem className='collapse-menu-item'>
                <NavLink>
                  <Button
                    color='link'
                    onClick={() => setCollapse(!collapse)}
                    className='d-inline-block item-icon-wrapper'>
                    {!group[0].parent ? 'Services' : group[0].parent}
                    <i className='material-icons'>expand_more</i>
                  </Button>
                </NavLink>
              </NavItem> */}
              {/* <Collapse open={collapse} navbar> */}
              {group
                .filter((item) => item.serviceId !== 'df2905fb-7199-4d6b-ad00-4a12d35f8164')
                .map(
                  (item) =>
                    moment().isSameOrBefore(item.expirationDate) && (
                      <SidebarNavItem
                        loading={loading}
                        success={success}
                        error={error}
                        key={item.serviceId}
                        item={item}
                        isLocalRoute={item.isLocalRoute}
                      />
                    )
                )}
              {/* {group.map(
                (item) =>
                  moment().isSameOrBefore(item.expirationDate) && (
                    <SidebarNavItem
                      loading={loading}
                      success={success}
                      error={error}
                      key={item.serviceId}
                      item={item}
                      isLocalRoute={item.isLocalRoute}
                    />
                  )
              )} */}
              {/* </Collapse> */}
            </div>
          ))}
        {navItems
          .filter((item) => item.to === '/logout')
          .map((item, key) => (
            <SidebarNavItem
              loading={loading}
              success={success}
              error={error}
              key={key}
              item={item}
              isLocalRoute={item.isLocalRoute}
            />
          ))}
      </Nav>
    </div>
  );
};

SidebarNavItems.propTypes = {
  service: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { user, loading, success, error } = state.Auth;
  return { user, loading, success, error };
};

export default connect(mapStateToProps, {})(SidebarNavItems);
