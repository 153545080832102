import { getAuthData } from '../helpers/customFunctions';
import { STRIPE_PAYMENT_METHOD } from '../helpers/constants';
import Cookies from 'universal-cookie';
import { APIClient } from '../helpers/apiClient';

const cookies = new Cookies();
const get = new APIClient().get;
const remove = new APIClient().delete;

// for testing porpuses
export const RUNNING_ON_LOCALHOST = 'RUNNING_ON_LOCALHOST';
export const DEV_INSTANCE = '.dev.z2b2.com';
export const LOCAL_INSTANCE = 'localhost';
export const NGROK = 'ngrok';

export const isDevInstance = () => {
  if (
    window.location.href.indexOf(DEV_INSTANCE) > -1 ||
    window.location.href.indexOf(LOCAL_INSTANCE) > -1 ||
    window.location.href.indexOf(NGROK) > -1
  ) {
    return true;
  } else {
    return false;
  }
};

export const resetServices = async () => {
  const { mid, token } = getAuthData();
  await get({ url: `/api/Merchant/ResetServices?merchantId=${mid}`, token });
  window.location.reload();
};

export const resetMethods = async customerId => {
  const { token } = getAuthData();
  await remove({
    url: `/api/Subscription/DeletePaymentMethods?customerId=${customerId}`,
    token,
  });
  cookies.remove(STRIPE_PAYMENT_METHOD);
  window.location.reload();
};
