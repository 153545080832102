export default function() {
  return [
    {
      name: 'Reports',
      to: '/reports',
      htmlBefore: '<i class="material-icons">article</i>',
      htmlAfter: '',
      isLocalRoute: true,
    },
    {
      name: 'Products & Services',
      to: '/home',
      htmlBefore: '<i class="material-icons">inventory</i>',
      htmlAfter: '',
      isLocalRoute: true,
    },
    {
      name: 'User Profile',
      to: '/user-profile',
      htmlBefore: '<i class="material-icons">person</i>',
      htmlAfter: '',
      isLocalRoute: true,
    },
    {
      name: 'Merchant Profile',
      to: '/merchant-profile',
      htmlBefore: '<i class="material-icons">storefront</i>',
      htmlAfter: '',
      isLocalRoute: true,
    },
    {
      name: 'Subscriptions',
      to: '/subscriptions',
      htmlBefore: '<i class="material-icons">info</i>',
      htmlAfter: '',
      isLocalRoute: true,
    },
    {
      name: 'Log Out',
      htmlBefore: '<i class="material-icons">logout</i>',
      to: '/logout',
      isLocalRoute: true,
    },
  ];
}
