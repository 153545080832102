import React from 'react';
import { Redirect } from 'react-router-dom';

// auth pages
const UserProfile = React.lazy(() => import('../views/UserProfile'));
const MerchantProfile = React.lazy(() => import('../views/MerchantProfile'));
const Subscriptions = React.lazy(() => import('../views/Subscriptions'));
const ProductList = React.lazy(() => import('../views/ProductList'));
const Invoice = React.lazy(() => import('../views/Invoice'));
const Reports = React.lazy(() => import('../views/Reports'));
const CustomerReport = React.lazy(() => import('../views/CustomerReport'));

// non auth pages
const Login = React.lazy(() => import('../views/Auth/Login'));
const Logout = React.lazy(() => import('../views/Auth/Logout'));
const ForgetPassword = React.lazy(() => import('../views/Auth/ForgetPassword'));
const Register = React.lazy(() => import('../views/Auth/Register/Register'));

// declare all routes
const authProtectedRoutes = [
  { path: `/home`, component: ProductList },
  { path: `/billing-invoice`, component: Invoice },
  { path: `/user-profile`, component: UserProfile },
  { path: `/merchant-profile`, component: MerchantProfile },
  { path: `/subscriptions`, component: Subscriptions },
  { path: `/reports`, component: Reports },
  { path: `/customer-report`, component: CustomerReport },
  { path: `/logout`, component: Logout },
  {
    path: `/`,
    exact: true,
    component: () => <Redirect to={`/home`} />,
  },
];

const publicRoutes = [
  { path: `/login`, component: Login },
  { path: `/forget-password`, component: ForgetPassword },
  { path: `/register`, component: Register },
];

const routes = [...authProtectedRoutes, ...publicRoutes];

export { authProtectedRoutes, publicRoutes, routes };
