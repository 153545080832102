export const AUTH_ERROR = 'AUTH_ERROR';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const CREATE_NEW_GROUP = 'CREATE_NEW_GROUP';
export const CREATE_NEW_GROUP_SUCCESS = 'CREATE_NEW_GROUP_SUCCESS';
export const CREATE_NEW_PRODUCT = 'CREATE_NEW_PRODUCT';
export const CREATE_NEW_PRODUCT_SUCCESS = 'CREATE_NEW_PRODUCT_SUCCESS';
export const DEACTIVATE_PRODUCT = 'DEACTIVATE_PRODUCT';
export const DEACTIVATE_PRODUCT_SUCCESS = 'DEACTIVATE_PRODUCT_SUCCESS';
export const EXTERNAL_ACCOUNTS = 'EXTERNAL_ACCOUNTS';
export const EXTERNAL_ACCOUNTS_SUCCESS = 'EXTERNAL_ACCOUNTS_SUCCESS';
export const GET_PRODUCTS_LIST_SUCCESS = 'GET_PRODUCTS_LIST_SUCCESS';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const PASSWORD_RECOVERY = 'ASK_PASSWORD_RECOVERY';
export const PASSWORD_RECOVERY_SUCCESS = 'ASK_PASSWORD_RECOVERY_SUCCESS';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const RELOAD_PAGE_AUTH = 'RELOAD_PAGE_AUTH';
export const RELOAD_PAGE_AUTH_SUCCESS = 'RELOAD_PAGE_AUTH_SUCCESS';
export const REQUEST_STRIPE_PAYMENT = 'REQUEST_STRIPE_PAYMENT';
export const REQUEST_STRIPE_PAYMENT_ERROR = 'REQUEST_STRIPE_PAYMENT_ERROR';
export const REQUEST_STRIPE_PAYMENT_SUCCESS = 'REQUEST_STRIPE_PAYMENT_SUCCESS';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const START_BROADCAST = 'START_BROADCAST';
export const START_BROADCAST_RESPONSE = 'START_BROADCAST_RESPONSE';
export const STRIPE_CONFIRM_SETUP = 'STRIPE_CONFIRM_SETUP';
export const STRIPE_CONFIRM_SETUP_SUCCESS = 'STRIPE_CONFIRM_SETUP_SUCCESS';
export const STRIPE_VALIDATION_PENDING = 'STRIPE_VALIDATION_PENDING';
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const UPDATE_FILE = 'UPDATE_FILE';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_MERCHANT = 'UPDATE_MERCHANT';
export const UPDATE_MERCHANT_SUCCESS = 'UPDATE_MERCHANT_SUCCESS';
export const UPDATE_PICTURE = 'UPDATE_PICTURE';
export const UPDATE_PICTURE_SUCCESS = 'UPDATE_PICTURE_SUCCESS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
