export const testRegister = (data, values) => {
  data.append('CreateMerchant.name', 'ArteDigital');
  data.append('CreateMerchant.dba', 'artedigital');
  data.append('CreateMerchant.yearsInBusiness', 10);
  data.append('CreateMerchant.federalTaxId', 'sdsfdsfds');
  data.append('CreateMerchant.businessLicense', 'dfdfsdfsd');
  data.append('CreateMerchant.email', values.email);
  data.append('CreateMerchant.phone', values.phone);
  data.append('CreateMerchant.website', 'https://artedigital-mx.com');
  data.append('CreateMerchant.city', 'Chula Vista');
  data.append('CreateMerchant.stateId', 31);
  data.append('CreateMerchant.countryId', 16);
  data.append('CreateMerchant.zip', '91911');
  data.append('CreateMerchant.statementDescriptor', 'Artedigital');
  data.append('CreateMerchant.description', 'testing register xD');
  data.append('CreateMerchant.address', '356 Theresa Way');
  data.append('CreateMerchant.isPublic', false);
  data.append('CreateMerchant.locationId', 'f2b87dfd-6e54-4472-b03f-6e98d763f5c1');
  data.append('CreateMerchant.categoryId', 5732);

  data.append('CreateUser.name', values.name);
  data.append('CreateUser.lastName', values.lastName);
  data.append('CreateUser.email', values.email);
  data.append('CreateUser.password', values.password);
  data.append('CreateUser.phoneNumber', values.phone);
  data.append('CreateUser.birthDay', '1995-05-30T07:00:00.000Z');
  data.append('CreateUser.legalStatus', 'representative');
  data.append('CreateUser.title', 'Manager');
  data.append('CreateUser.userGender', 'Male');

  data.append('CreateUserDetails.politicalExposure', false);
  data.append('CreateUserDetails.driverLicence', '123456789');
  data.append('CreateUserDetails.socialSecurity', 'Dxssdsdsdfd');
  data.append('CreateUserDetails.address', '356 Theresa Way');
  data.append('CreateUserDetails.city', 'Chula Vista');
  data.append('CreateUserDetails.stateId', 31);
  data.append('CreateUserDetails.countryId', 16);
  data.append('CreateUserDetails.zip', '91911');

  data.append('Services[]', '93950168-66e0-4f8a-9fea-5fccdb913ed5');
  data.append('Services[]', 'df2905fb-7199-4d6b-ad00-4a12d35f8164');

  // file uploaded
  data.append('file', undefined);

  return data;
};
