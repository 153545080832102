import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Button } from 'reactstrap';

import { Dispatcher, Constants } from '../../../flux';

class SidebarMainNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR,
    });
  }

  render() {
    const { hideLogoText } = this.props;
    return (
      <div className='main-navbar'>
        <Navbar
          className='align-items-stretch bg-white flex-md-nowrap border-bottom p-0'
          type='light'>
          <div className='d-table m-auto'>
            <img
              id='main-logo'
              className='d-inline-block align-top mr-1 main-logo'
              style={{ maxWidth: '100%' }}
              src={require('../../../assets/images/z2b2-logo.png')}
              alt='Z2B2 Dashboard'
            />
          </div>

          <Button
            color='link'
            outline
            className='toggle-sidebar d-sm-inline d-md-none d-lg-none'
            onClick={this.handleToggleSidebar}>
            <i className='material-icons'>&#xE5C4;</i>
          </Button>
        </Navbar>
      </div>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false,
};

export default SidebarMainNavbar;
