import React from 'react';
import Routes from './routes/';
import { connect } from 'react-redux';
import { validateEmail, checkActiveUser } from './redux/actions';
import { Z2B2_MERCHANT_TOKEN, Z2B2_MERCHANT_UID } from './helpers/constants';
import Cookies from 'universal-cookie';
import Loader from './components/Loader';

import { isDevInstance } from './testing/utils';

const cookies = new Cookies();

const App = ({ validateEmail, checkActiveUser, initializing }) => {
  const token = cookies.get(Z2B2_MERCHANT_TOKEN);
  const uid = JSON.parse(localStorage.getItem(Z2B2_MERCHANT_UID));

  React.useEffect(() => {
    isDevInstance();
    if (uid && token) checkActiveUser({ uid: uid.uid, token, mid: uid.mid });
    checkToken();
  }, []);

  const checkToken = () => {
    const url = window.location.search;
    const urlParam = new URLSearchParams(url);
    const tk = urlParam.get('tk');
    if (tk) validateEmail(tk);
  };

  return initializing ? <Loader /> : <Routes />;
};

const mapStateToProps = (state) => {
  const { error, initializing, success } = state.Auth;
  return { error, initializing, success };
};

export default connect(mapStateToProps, { validateEmail, checkActiveUser })(App);
