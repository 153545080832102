import React from 'react';
import './Loader.scss';

const Loader = () => {
  return (
    <div className='main-loader'>
      <div className='loading'>
        <div className='dot' />
        <div className='dot' />
        <div className='dot' />
        <div className='dot' />
        <div className='dot' />
      </div>
    </div>
  );
};

export default Loader;
