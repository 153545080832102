import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouteNavLink } from 'react-router-dom';
import { NavItem, NavLink } from 'shards-react';
import Cookies from 'universal-cookie';
import {
  BROADCAST_SERVICE_ID,
  Z2B2_MERCHANT_TOKEN,
  Z2B2_MERCHANT_UID,
} from '../../../helpers/constants';

const cookies = new Cookies();

const SidebarNavItem = ({ item, isLocalRoute, loading, success, error }) => {
  const [href, setHref] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    const setLinkWithUrl = () => {
      if (item.serviceId === BROADCAST_SERVICE_ID) {
        const token = cookies.get(Z2B2_MERCHANT_TOKEN);
        const uid = JSON.parse(localStorage.getItem(Z2B2_MERCHANT_UID));
        const auth = JSON.stringify([uid.uid, token]);
        setHref(`${item.to}login?auth=${auth}`);
      } else {
        setHref(item.to);
      }
    };
    setLinkWithUrl();
  }, []);

  React.useEffect(() => {
    if (loading || error || success) setDisabled(true);
    else setDisabled(false);
  }, [loading, success, error]);

  return isLocalRoute ? (
    <NavItem>
      <NavLink
        disabled={disabled}
        className='services-menu'
        tag={(props) => <RouteNavLink {...props} />}
        to={item.to}>
        {item.htmlBefore && (
          <div
            className='d-inline-block item-icon-wrapper'
            dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
          />
        )}
        {item.icon && (
          <div className='d-inline-block item-icon-wrapper'>
            <i className='material-icons'>{item.icon}</i>
          </div>
        )}
        {item.name && <span>{item.name}</span>}
        {item.htmlAfter && (
          <div
            className='d-inline-block item-icon-wrapper'
            dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
          />
        )}
      </NavLink>
    </NavItem>
  ) : (
    <NavItem>
      <NavLink
        disabled={disabled}
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        className='services-menu'>
        <div className='d-inline-block item-icon-wrapper'>
          <i className='material-icons'>{item.icon}</i>
        </div>
        {item.name}
      </NavLink>
    </NavItem>
  );
};

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object,
};

export default SidebarNavItem;
