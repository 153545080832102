import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { Z2B2_MERCHANT_TOKEN, Z2B2_MERCHANT_UID } from '../helpers/constants';
//import routes
import { authProtectedRoutes, publicRoutes } from './routes';

//import layouts
import LoginLayout from '../layouts/LoginLayout';
import AuthLayout from '../layouts/AuthLayout';

const cookies = new Cookies();

// handle auth and authorization
const AppRoute = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const mid = JSON.parse(localStorage.getItem(Z2B2_MERCHANT_UID)) || null;
        const token = cookies.get(Z2B2_MERCHANT_TOKEN) || null;
        if (isAuthProtected && (!mid || !token)) {
          return <Redirect to={{ pathname: `/login`, state: { from: props.location } }} />;
        } else if (!isAuthProtected && mid && token) {
          return <Redirect to={{ pathname: `/home`, state: { from: props.location } }} />;
        }
        // authorized so return component
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

/**
 * Main Route component
 */
const Routes = ({ user }) => {
  const [protectedRoutes, setProtectedRoutes] = React.useState(authProtectedRoutes);

  React.useEffect(() => {
    if (user) {
      const { services } = user;
      const disabledServices = services.filter(
        (service) => service.isLocalRoute && service.status === 0
      );
      const result = authProtectedRoutes.filter(
        (item) => !disabledServices.some((item2) => item.path === item2.to)
      );
      setProtectedRoutes(result);
    }
  }, [user]);

  return (
    // rendering the router with layout
    <BrowserRouter>
      <React.Fragment>
        <Suspense fallback={<div></div>}>
          <Switch>
            {/* public routes */}
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={LoginLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {/* private/auth protected routes */}
            {protectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={AuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
          </Switch>
        </Suspense>
      </React.Fragment>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  const { user } = state.Auth;
  return { user };
};

export default connect(mapStateToProps, {})(Routes);
