// SERVICES STATUS
export const SERVICE_STATUS_DISABLED = 0;
export const SERVICE_STATUS_PENDING = 1;
export const SERVICE_STATUS_ACTIVE = 2;
export const SERVICE_STATUS_CANCELLED = 3;

export const BROADCAST_SERVICE_ID = 'df2905fb-7199-4d6b-ad00-4a12d35f8164';
export const STRIPE_VALIDATION_LINK = 'STRIPE_VALIDATION_LINK';

export const STRIPE_PAYMENT_METHOD = 'STRIPE_PAYMENT_METHOD';
export const Z2B2_MERCHANT_TOKEN = 'Z2B2_MERCHANT_TOKEN';
export const HAS_AN_ACTIVE_SERVICE = 'HAS_AN_ACTIVE_SERVICE';
export const PENDING_SERVICES = 'PENDING_SERVICES';
export const STRIPE_CLIENT_DATA = 'STRIPE_CLIENT_DATA';
export const Z2B2_MERCHANT_UID = 'Z2B2_MERCHANT_UID';

// which properties we don't want to show on columns
export const propertiesToShowOnColumns = {
  /*********  MERCHANTS *********/
  payments: {
    invalid: [
      'amountCaptured',
      'amountRefunded',
      'created',
      'disputed',
      'paid',
      'refunded',
      'refunds',
      'status',
    ],
    valid: {
      net: { label: 'Net', width: 20 },
      amount: { label: 'Amount', width: 40 },
      applicationFeeAmount: { label: 'Fee', width: 20 },
      currency: { label: 'Currency', width: 20 },
      customerName: { label: 'Customer', width: 50 },
      description: { label: 'Description', width: 50 },
      customerId: { label: 'Customer ID', width: 40 },
    },
  },
};
