import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIClient } from '../../helpers/apiClient';
import {
  STRIPE_PAYMENT_METHOD,
  Z2B2_MERCHANT_TOKEN,
  HAS_AN_ACTIVE_SERVICE,
  PENDING_SERVICES,
  STRIPE_CLIENT_DATA,
  Z2B2_MERCHANT_UID,
  SERVICE_STATUS_DISABLED,
  SERVICE_STATUS_PENDING,
  SERVICE_STATUS_ACTIVE,
  SERVICE_STATUS_CANCELLED,
} from '../../helpers/constants';
import {
  convertErrorsIntoArray,
  createSetupIntent,
  encryptData,
  findValuesByName,
  getAuthData,
  getPaymentMethodId,
  getPaymentMethods,
  getReports,
  getStatus,
  parseStripeValidationErrors,
  redirectUserIfHasActiveServices,
} from '../../helpers/customFunctions';
import { testRegister } from '../../helpers/test';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { isDevInstance } from '../../testing/utils';

import {
  CANCEL_SUBSCRIPTION,
  CHANGE_PASSWORD,
  CREATE_NEW_GROUP,
  CREATE_NEW_PRODUCT,
  DEACTIVATE_PRODUCT,
  EXTERNAL_ACCOUNTS,
  LOGIN_USER,
  LOGOUT_USER,
  PASSWORD_RECOVERY,
  REGISTER_USER,
  RELOAD_PAGE_AUTH,
  REQUEST_STRIPE_PAYMENT,
  SET_NEW_PASSWORD,
  START_BROADCAST,
  STRIPE_CONFIRM_SETUP,
  UPDATE_FILE,
  UPDATE_MERCHANT,
  UPDATE_PICTURE,
  UPDATE_PRODUCT,
  UPDATE_USER,
  VALIDATE_EMAIL,
} from './constants';

import {
  apiError,
  cancelSubscriptionSuccess,
  changePasswordSuccess,
  checkActiveUserSuccess,
  createNewGroupSuccess,
  createNewProductSuccess,
  deactivateProductSuccess,
  externalAccountsActionSuccess,
  getProductsPerMerchantSuccess,
  loginUserSuccess,
  registerUserError,
  registerUserSuccess,
  requestStripePaymentSuccess,
  setNewPasswordSuccess,
  startBroadcastResponse,
  stripeConfirmSetupSuccess,
  stripeValidationPending,
  successMessage,
  updateFileSuccess,
  updateMerchantSuccess,
  updatePictureSuccess,
  updateProductSuccess,
  updateUserSuccess,
  validateEmailSuccess,
} from './actions';

/**
 * Instances of classes that we need
 */
const cookies = new Cookies();
const get = new APIClient().get;
const post = new APIClient().post;
const postForm = new APIClient().postForm;
const update = new APIClient().put;
const remove = new APIClient().delete;
const updateForm = new APIClient().updateForm;

// IMAGE PATH URL
const IMAGE_PATH = `${process.env.REACT_APP_API_URL}/ProfilePicture/`;

const replaceImagesURL = async data => {
  return await {
    ...data,
    merchantProfilePicture: data.merchantProfilePicture
      ? IMAGE_PATH + data.merchantProfilePicture
      : null,
    profilePicture: data.profilePicture ? IMAGE_PATH + data.profilePicture : null,
  };
};

const getMerchantDetails = async ({ uid, token }) => {
  return await get({
    url: `/api/Merchant/GetMerchantDetails?userId=${uid}`,
    token,
  });
};

function* closeSession() {
  yield call(() => {
    cookies.remove(STRIPE_PAYMENT_METHOD);
    cookies.remove(Z2B2_MERCHANT_TOKEN);
    localStorage.removeItem(HAS_AN_ACTIVE_SERVICE);
    localStorage.removeItem(PENDING_SERVICES);
    localStorage.removeItem(STRIPE_CLIENT_DATA);
    localStorage.removeItem(Z2B2_MERCHANT_UID);
    window.location.reload();
  });
}

/**
 * Register user
 * @param {*} payload - object with user data
 */
function* registerUser({ payload: { values, testing, paymentMethod } }) {
  try {
    const AccountBankAccountOptions = {
      AccountHolderName: 'Holder', //text input
      AccountHolderType: `company / individual`, //select
      AccountNumber: '000123456789', //number input
      Country: 'US', // disabled according which country they take
      Currency: 'USD / CAD', //automatic disabled
      RoutingNumber: '110000000', // number input
    };

    const AccountCardOptions = {
      AddressCity: '', // input text
      AddressCountry: '', // input text
      AddressLine1: '', // input text
      AddressLine2: '', // input text
      AddressState: '', // input text
      AddressZip: '', // input number
      Currency: '', // don't show first test
      Cvc: '', // input text
      ExpMonth: 0, // input number
      ExpYear: 1, // input number
      Name: '', // input text
      Number: '', //input numero
    };

    // new FormData Object to send info
    const data = new FormData();

    if (testing) {
      testRegister(data, values);
    } else {
      const {
        attachment, //this is the file, not sure why does this property is added as "undefined" (but it works)
      } = values;

      // getting services selected by user from the values and create a new array with just the services ID's
      const services = Object.values(findValuesByName('service', values)).map(item => {
        let array = [];
        array.push(item.id);
        return array;
      });

      // separating values to append into data
      // CreateMerchant
      data.append('CreateMerchant.name', values.legalBusinessName);
      data.append('CreateMerchant.dba', values.dba);
      data.append('CreateMerchant.yearsInBusiness', values.yearsInBusiness);
      data.append('CreateMerchant.federalTaxId', values.federalTaxID);
      data.append('CreateMerchant.businessLicense', values.businessLicense);
      data.append('CreateMerchant.email', values.merchantEmail);
      data.append('CreateMerchant.phone', values.merchantPhone);
      data.append('CreateMerchant.website', values.merchantWebsite);
      data.append('CreateMerchant.city', values.merchantCity);
      data.append('CreateMerchant.stateId', values.merchantState);
      data.append('CreateMerchant.countryId', values.merchantCountry);
      data.append('CreateMerchant.zip', values.merchantZipCode);
      data.append('CreateMerchant.statementDescriptor', values.statementDescriptor);
      data.append('CreateMerchant.description', values.description);
      data.append('CreateMerchant.address', values.merchantAddress);
      data.append('CreateMerchant.isPublic', values.isPublic === 'Public' ? true : false);
      data.append('CreateMerchant.locationId', values.merchantLocation);
      data.append('CreateMerchant.categoryId', values.merchantCode);

      // CreateUser
      data.append('CreateUser.name', values.firstName);
      data.append('CreateUser.lastName', values.lastName);
      data.append('CreateUser.email', values.userEmail);
      data.append('CreateUser.password', values.password);
      data.append('CreateUser.phoneNumber', values.userPhoneNumber);
      data.append('CreateUser.birthDay', values.userBirth);
      data.append('CreateUser.legalStatus', values.legalStatus);
      data.append('CreateUser.title', values.title);
      data.append('CreateUser.gender', values.userGender.toLowerCase());

      // CreateUserDetails
      data.append(
        'CreateUserDetails.politicalExposure',
        values.politicalExposure === 'Yes' ? true : false
      );
      data.append('CreateUserDetails.driverLicence', values.driverLicense);
      data.append('CreateUserDetails.socialSecurity', values.socialSecurity);
      data.append('CreateUserDetails.address', values.userAddress);
      data.append('CreateUserDetails.city', values.userCity);
      data.append('CreateUserDetails.stateId', values.userState);
      data.append('CreateUserDetails.countryId', values.userCountry);
      data.append('CreateUserDetails.zip', values.userZipCode);

      if (paymentMethod === 'ach') {
        data.append('AccountBank.AccountHolderName', values.accountHolderName);
        data.append('AccountBank.AccountHolderType', values.accountHolderType);
        data.append('AccountBank.AccountNumber', values.accountNumber);
        data.append('AccountBank.Country', values.country);
        data.append('AccountBank.Currency', values.currency);
        data.append('AccountBank.RoutingNumber', values.routingNumber);
        data.append('AccountCard', null);
      }
      if (paymentMethod === 'card') {
        data.append('AccountCard.AddressCity', values.addressCity);
        data.append('AccountCard.AddressCountry', values.addressCountry);
        data.append('AccountCard.AddressLine1', values.addressLine1);
        data.append('AccountCard.AddressLine2', values.addressLine2);
        data.append('AccountCard.AddressState', values.addressState);
        data.append('AccountCard.AddressZip', values.addressZip);
        data.append('AccountCard.Currency', values.currency);
        data.append('AccountCard.Cvc', values.cvc);
        data.append('AccountCard.Name', values.name);
        data.append('AccountCard.Number', values.number);
        data.append('AccountCard.ExpMonth', values.expMonth);
        data.append('AccountCard.ExpYear', values.expYear);
        data.append('AccountBank', null);
      }

      // subservices array
      services.forEach(item => data.append('Services[]', item));

      // file uploaded
      data.append('file', attachment);
    }

    // for (var pair of data.entries()) {
    //   console.log(pair[0] + ' - ' + pair[1]);
    // }

    const register = yield call(postForm, { url: `/api/Merchant/Post`, data, json: true });

    if (register.response.status === 200) {
      yield put(
        registerUserSuccess(
          'Thank you for registering with us, please check your email address to continue.'
        )
      );
      yield call(() => {
        localStorage.removeItem('Z2B2-merchant-codes');
        localStorage.removeItem('Z2B2-userState');
        localStorage.removeItem('Z2B2-merchantLocation');
      });
    } else if (register.response.status === 409) {
      yield put(apiError(register.json.response));
    } else {
      if (register.json.errors) {
        const errors = convertErrorsIntoArray(register.json.errors);
        yield put(
          registerUserError({ message: 'There was an error, please try again later.', errors })
        );
      } else {
        yield put(apiError(register.json));
      }
    }
  } catch (error) {
    yield put(apiError('There was an error in our end, please try again later.'));
    console.error(error);
  }
}

/**
 * Validate user email
 * @param {*} payload - token
 */
function* validateEmail({ payload: token }) {
  try {
    const validateToken = yield call(get, {
      url: `/api/Account/ConfirmEmail?tk=${token}`,
    });
    if (validateToken.response.status === 200) {
      yield put(validateEmailSuccess('Thanks for validating your account, now you can log in.'));
    } else {
      yield put(apiError('There was an error while validating your account, try again later.'));
    }
  } catch (error) {}
}

/**
 * Login the user
 * @param {*} payload - email and password
 */
function* login({ payload: { email, password, history } }) {
  try {
    const login = yield call(post, {
      url: '/api/Auth/Login',
      data: { email, password },
      json: true,
    });

    if (login.response.status === 200) {
      const { token, userId } = login.json;

      const encryptedToken = encryptData(token);
      const encryptedUid = encryptData(userId);

      const merchantDetails = yield getMerchantDetails({
        uid: encryptedUid,
        token: encryptedToken,
      });

      if (merchantDetails.response.status === 200) {
        const { customerId, merchantId, services, group, accountId } = merchantDetails.json;

        const { total, payments } = yield getReports(encryptedToken);

        const { setupIntent } = yield createSetupIntent(customerId, encryptedToken);
        localStorage.setItem(
          STRIPE_CLIENT_DATA,
          JSON.stringify({
            clientSecret: setupIntent,
            customerId,
            accountId,
          })
        );

        const { disabledReason, pendingVerification, errors } = yield call(getStatus, [
          accountId,
          encryptedToken,
        ]);

        if (disabledReason)
          yield put(
            stripeValidationPending({
              pendingVerification,
              errors: parseStripeValidationErrors(errors),
            })
          );

        const { paymentMethods, status } = yield getPaymentMethods(customerId, encryptedToken);

        // add to localStorage the payment methods in order to consult them later when checkout
        if (status === 200)
          cookies.set(STRIPE_PAYMENT_METHOD, JSON.stringify(paymentMethods), {
            path: '/',
          });

        const encryptedMID = encryptData(merchantId);
        const merchant = yield replaceImagesURL(merchantDetails.json);
        // filter by active services
        const activeServices = services.filter(item => item.active);

        const items = activeServices.filter(item => item.status === SERVICE_STATUS_PENDING);
        if (items.length > 0) localStorage.setItem(PENDING_SERVICES, JSON.stringify(items));

        cookies.set(Z2B2_MERCHANT_TOKEN, encryptedToken, {
          path: '/',
          // expires: date.setDate(date.getDate() + 1),
          // domain: '.Z2B2.com',
          // secure: true,
        });
        localStorage.setItem(
          Z2B2_MERCHANT_UID,
          JSON.stringify({ uid: encryptedUid, mid: encryptedMID })
        );
        yield redirectUserIfHasActiveServices(activeServices, history);

        yield put(
          loginUserSuccess({
            user: { ...merchant, services: activeServices },
            groups: group,
            reports: { total, payments },
          })
        );
        yield getProductsPerMerchant({
          mid: encryptedMID,
          token: encryptedToken,
        });
      } else {
        yield put(apiError('There was an error retrieving customer details.'));
        return;
      }
    } else if (login.response.status === 409) {
      yield put(apiError(login.json.response));
    } else {
      yield put(apiError('There was an error, please try again later.'));
    }
  } catch (error) {
    // yield put(apiError(error));
    console.error('error on login: ', error);
  }
}

/**
 * Retrieve the user info when reload
 * @param {*} payload - uid token
 */
function* checkActiveUser({ payload: { uid, token, mid } }) {
  try {
    const pendingPaymentServices = JSON.parse(localStorage.getItem(PENDING_SERVICES));

    const merchantDetails = yield getMerchantDetails({ uid, token });

    if (merchantDetails.response.status === 200) {
      const { services, group, accountId } = merchantDetails.json;

      const { total, payments } = yield getReports(token);

      const { disabledReason, stripeLink, pendingVerification, errors } = yield call(getStatus, [
        accountId,
        token,
      ]);

      if (disabledReason)
        yield put(
          stripeValidationPending({
            stripeLink,
            pendingVerification,
            errors: parseStripeValidationErrors(errors),
          })
        );

      const merchant = yield replaceImagesURL(merchantDetails.json);

      // filter by active services
      const activeServices = services.filter(item => item.active);

      if (!pendingPaymentServices) {
        const items = activeServices.filter(item => item.status === SERVICE_STATUS_PENDING);
        if (items.length > 0) localStorage.setItem(PENDING_SERVICES, JSON.stringify(items));
      }

      const { paymentMethods, status } = yield getPaymentMethods(
        merchantDetails.json.customerId,
        token
      );
      // add to localStorage the payment methods in order to consult them later when checkout
      if (status === 200)
        cookies.set(STRIPE_PAYMENT_METHOD, JSON.stringify(paymentMethods), {
          path: '/',
        });

      const url = window.location.search;
      const urlParam = new URLSearchParams(url);
      const setup_intent = urlParam.get('setup_intent');
      const setup_intent_client_secret = urlParam.get('setup_intent_client_secret');
      const redirect_status = urlParam.get('redirect_status');
      if (
        pendingPaymentServices &&
        setup_intent &&
        setup_intent_client_secret &&
        redirect_status === 'succeeded'
      ) {
        const { setupIntent } = yield createSetupIntent(merchantDetails.json.customerId, token);
        localStorage.setItem(
          STRIPE_CLIENT_DATA,
          JSON.stringify({
            clientSecret: setupIntent,
            customerId: merchantDetails.json.customerId,
            accountId,
          })
        );
        yield put(
          stripeConfirmSetupSuccess({
            redirect_status,
          })
        );
        window.history.replaceState({}, document.title, '/subscriptions');
      }

      yield getProductsPerMerchant({ mid, token });
      yield put(
        checkActiveUserSuccess({
          user: { ...merchant, services: activeServices },
          groups: group,
          reports: { total, payments },
        })
      );
    } else {
      yield put(apiError('There was an error retrieving the user details.'));
      yield closeSession();
    }
  } catch (error) {
    console.error('error when reload page: ', error);
    yield closeSession();
  }
}

/**
 * Logout the user
 * @param {*} - history from props in order to push the user to the login page
 */
function* logout({ payload: { history } }) {
  try {
    yield closeSession();
    history.push('/login');
  } catch (error) {}
}

/**
 * recover password process (1. send email for recover password, 2. set the new password)
 */
function* sendEmailForPasswordRecovery({ payload: email }) {
  try {
    const sendEmail = yield call(post, {
      url: '/api/Account/ForgotPassword',
      data: email,
    });
    yield put(successMessage(`If this user exists, you'll get an email, check your inbox.`));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* setNewPassword({ payload: { uid, newPassword } }) {
  try {
    const forgetPassword = yield call(update, {
      url: `/api/Account/NewPassword?user=${uid}&password=${newPassword}`,
    });
    if (forgetPassword.status === 200) {
      yield put(setNewPasswordSuccess(`Password successfully changed, now you can log in.`));
    } else {
      yield put(apiError(`We couldn't change the password, please try again.`));
    }
  } catch (error) {}
}

/**
 * change password
 */
function* changePassword({ payload: { oldPassword, newPassword } }) {
  try {
    const auth = getAuthData();
    const { uid, token } = auth;

    const changePassword = yield call(update, {
      url: `/api/Customer/ChangePassword?customerId=${uid}`,
      data: { oldPassword, newPassword },
      token,
    });

    if (changePassword.status === 200) {
      yield put(changePasswordSuccess('Password has been changed successfully.'));
    } else {
      yield put(apiError('There was an error while updating your password.'));
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Update user info
 * @param {*} payload - data
 */
function* updateUser({ payload: data }) {
  try {
    const auth = getAuthData();
    const { uid, token } = auth;

    const user = {
      name: data.name,
      lastName: data.lastName,
      birthDay: data.birthDay,
      address: data.address,
      city: data.city,
      stateId: data.state,
      countryId: data.country,
      zip: data.zip.toString(),
      gender: data.gender,
      driverLicence: data.driverLicence,
      politicalExposure: data.politicalExposure,
      legalStatus: data.legalStatus,
      title: data.title,
      socialSecurity: data.socialSecurity,
    };

    const updateUser = yield call(update, {
      url: `/api/Account/UpdateUser?userId=${uid.uid}`,
      data: user,
      token,
    });
    if (updateUser.status === 200) {
      const merchantDetails = yield call(get, {
        url: `/api/Merchant/GetMerchantDetails?userId=${uid.uid}`,
        token,
      });
      if (merchantDetails.response.status === 200) {
        const user = {
          ...merchantDetails.json,
          merchantProfilePicture: merchantDetails.json.merchantProfilePicture
            ? IMAGE_PATH + merchantDetails.json.merchantProfilePicture
            : null,
          profilePicture: merchantDetails.json.profilePicture
            ? IMAGE_PATH + merchantDetails.json.profilePicture
            : null,
        };
        yield put(updateUserSuccess({ success: 'User updated successfully.', user }));
      } else {
        yield put(apiError('Error trying to retrieve user details, please reload.'));
        return;
      }
    } else {
      const error = yield updateUser.json();
      yield put(apiError(error));
      return;
    }
  } catch (error) {
    console.error('error when update: ', error);
  }
}

/**
 * Update merchant info
 * @param {*} payload - data
 */
function* updateMerchant({ payload: data }) {
  try {
    const auth = getAuthData();
    const { uid, token, mid } = auth;

    const merchant = {
      address: data.merchantAddress,
      businessLicense: data.businessLicense,
      categoryId: data.categoryId,
      city: data.merchantCity,
      countryId: data.country,
      dba: data.dba,
      description: data.description,
      email: data.merchantEmail,
      federalTaxId: data.federalTaxId,
      isPublic: data.isPublic,
      locationId: data.locationId,
      name: data.merchantName,
      phone: data.merchantPhone,
      stateId: data.state,
      statementDescriptor: data.statementDescriptor,
      website: data.website,
      yearsInBusiness: data.yearsInBusiness,
      zip: data.merchantZip.toString(),
    };

    const updateMerchant = yield call(update, {
      url: `/api/Merchant/UpdateMerchant`,
      data: merchant,
      token,
    });

    if (updateMerchant.status === 200) {
      const merchantDetails = yield call(get, {
        url: `/api/Merchant/GetMerchantDetails?userId=${uid}`,
        token,
      });
      if (merchantDetails.response.status === 200) {
        const merchant = {
          ...merchantDetails.json,
          merchantProfilePicture: merchantDetails.json.merchantProfilePicture
            ? IMAGE_PATH + merchantDetails.json.merchantProfilePicture
            : null,
          profilePicture: merchantDetails.json.profilePicture
            ? IMAGE_PATH + merchantDetails.json.profilePicture
            : null,
        };
        yield put(
          updateMerchantSuccess({
            success: 'Merchant information updated successfully.',
            merchant,
          })
        );
      } else {
        yield put(apiError('Error trying to retrieve merchant details, please reload.'));
        return;
      }
    } else {
      yield put(apiError('There was an error trying to update merchant information.'));
      return;
    }
  } catch (error) {}
}

/**
 * Update user profile picture
 * @param {*} payload - image file
 */
function* updateFile({ payload: { file, type, accountId } }) {
  try {
    const auth = getAuthData();
    const { uid, token } = auth;

    const data = new FormData();
    data.append('file', file);

    const url =
      type === 'bank'
        ? '/api/Account/SetBankFile'
        : type === 'id'
        ? `/api/Account/SetFile?userId=${uid}`
        : type === 'tax' && `/api/Account/SetTaxId`;

    const upload = yield call(updateForm, {
      url,
      data,
      token,
    });

    if (upload.response.status === 200) {
      const { disabledReason, pendingVerification, errors } = yield call(getStatus, [
        accountId,
        token,
      ]);
      if (disabledReason)
        yield put(
          stripeValidationPending({
            pendingVerification,
            errors: parseStripeValidationErrors(errors),
          })
        );
      yield put(updateFileSuccess('File uploaded successfully.'));
    } else {
      yield put(apiError(upload.string));
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Update user profile picture
 * @param {*} payload - image file
 */
function* updatePicture({ payload: { file, type } }) {
  try {
    const auth = getAuthData();
    const { uid, mid, token } = auth;

    const data = new FormData();
    data.append('file', file);

    const update = yield call(updateForm, {
      url: `/api/Account/UserPhoto?userId=${type === 'user' ? uid : mid}`,
      data,
      token,
    });

    if (update.response.status === 200) {
      yield put(successMessage('Image updated successfully.'));
      yield put(updatePictureSuccess({ path: update.string, type }));
    } else {
      yield put(apiError(update.message));
    }
  } catch (error) {
    console.error('error image: ', error);
    yield put(apiError(error));
  }
}

/**
 * delete payments
 * @param {*} payload - array of payments ids
 */
async function createToken(data) {
  const { isCard, stripe, values } = data;
  if (isCard) {
    const { cardElement } = data;
    return await stripe.createToken(cardElement, {
      ...values,
      currency: values.currency.toLowerCase(),
    });
  } else {
    return await stripe.createToken('bank_account', {
      ...values,
      account_number: values.account_number.toString(),
      routing_number: values.routing_number.toString(),
      country: values.country.split(',')[0],
    });
  }
}
function* externalAccountsAction({ payload: { action, data } }) {
  try {
    const auth = getAuthData();
    const { token, uid } = auth;

    switch (action) {
      case 'create':
        {
          const stripeToken = yield createToken(data);
          if (stripeToken.token) {
            const sendToken = yield call(update, {
              url: `/api/Merchant/AddExternalAccount?token=${stripeToken.token.id}`,
              token,
            });
            if (sendToken.status === 200) {
              const merchantDetails = yield getMerchantDetails({ uid, token });
              if (merchantDetails.response.status === 200) {
                const merchant = yield replaceImagesURL(merchantDetails.json);
                yield put(
                  externalAccountsActionSuccess({
                    success: 'Payment info added successfully',
                    user: merchant,
                  })
                );
              }
            } else {
              const error = yield sendToken.json();
              yield put(apiError(error));
            }
          }
          if (stripeToken.error) yield put(apiError(stripeToken.error.message));
        }
        break;
      case 'delete': {
        const erasePayments = yield call(update, {
          url: '/api/Merchant/DeleteExternalAccounts',
          data,
          token,
        });
        if (erasePayments.status === 200) {
          const merchantDetails = yield getMerchantDetails({ uid, token });
          if (merchantDetails.response.status === 200) {
            const merchant = yield replaceImagesURL(merchantDetails.json);
            yield put(
              externalAccountsActionSuccess({
                success: 'Payment info deleted successfully',
                user: merchant,
              })
            );
          }
        } else {
          yield put(apiError('Error deleting external account.'));
        }
        break;
      }
      case 'setDefault': {
        const setDefault = yield call(update, {
          url: `/api/Merchant/SetDefaultExternalAccount?externalAccountId=${data}`,
          token,
        });
        if (setDefault.status === 200) {
          const merchantDetails = yield getMerchantDetails({ uid, token });
          if (merchantDetails.response.status === 200) {
            const merchant = yield replaceImagesURL(merchantDetails.json);
            yield put(
              externalAccountsActionSuccess({
                success: 'Default payment updated successfully',
                user: merchant,
              })
            );
          }
        } else {
          yield put(apiError('Error updating default payment.'));
        }
        break;
      }
      default:
        return;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Request payment
 * @param {*} payload - payment details
 */
// function to setup future payment
function* stripeConfirmSetup({ payload: { stripe, elements, services, customerId, accountId } }) {
  try {
    const pendingServices = services.map(service => ({
      ...service,
      status: SERVICE_STATUS_PENDING,
    }));
    localStorage.setItem(PENDING_SERVICES, JSON.stringify(pendingServices));

    const callStripe = yield call(stripe.confirmSetup, {
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/subscriptions`,
      },
    });
    if (callStripe.error) yield put(apiError(callStripe.error.message));
    return;
  } catch (error) {
    console.error('error on payment request: ', error);
  }
}
// function to pay after confirm setup
function* requestStripePayment({
  payload: { merchantId, customerId, paymentMethodId, accountId },
}) {
  try {
    const { token, uid } = getAuthData();
    const services = JSON.parse(localStorage.getItem(PENDING_SERVICES));

    const serviceIds = services.map(item => item.serviceId);

    const paymentMethod = yield getPaymentMethodId(paymentMethodId, customerId, token);

    const requestPayment = yield call(post, {
      url: '/api/Subscription/CreateSubscription',
      data: { customerId, serviceIds, merchantId, paymentMethodId: paymentMethod, accountId },
      token,
    });

    if (requestPayment.response.status === 200) {
      const merchantDetails = yield getMerchantDetails({ uid, token });
      const { services } = merchantDetails.json;

      const activeServices = services.filter(item => item.active === true);

      localStorage.removeItem(PENDING_SERVICES);
      yield put(
        requestStripePaymentSuccess({
          services: activeServices,
          message: 'Payment processed successfully.',
        })
      );
    } else if (requestPayment.response.status === 402) {
      yield put(apiError('The merchant has not finish all the stripe account details.'));
    } else {
      const error = yield requestPayment.response.json();
      yield put(apiError(error));
    }
  } catch (error) {
    console.error(error);
  }
}
// function to cancel subscriptions
function* cancelSubscription({ payload: { service } }) {
  try {
    const { mid, token } = getAuthData();
    const response = yield call(update, {
      url: `/api/Merchant/UpdateMerchantServiceStatus?merchantId=${mid}&serviceId=${service.serviceId}`,
      token,
    });
    if (response.status === 200) {
      yield put(cancelSubscriptionSuccess('Subscription canceled successfully.'));
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get Products List per merchant
 * @param {*} payload - payment details
 */
function* createNewGroup({ payload: { title, members, description, option } }) {
  try {
    const auth = getAuthData();
    const { token, mid, uid } = auth;

    let createGroupResponse = null;

    if (option === 'addedManually') {
      const data = {
        name: title,
        description,
        members,
        merchantId: mid,
      };

      createGroupResponse = yield call(post, { url: `/api/Group/PostGroupContacts`, data, token });
    } else if (option === 'addedByFile') {
      const data = new FormData();

      data.append('name', title);
      data.append('description', description);
      data.append('file', members);
      data.append('merchantId', mid);

      createGroupResponse = yield call(postForm, { url: '/api/Group/Post', data, token });
    }

    if (createGroupResponse.response.status === 200) {
      const merchantDetails = yield getMerchantDetails({ uid, token });
      const { group } = merchantDetails.json;
      yield put(createNewGroupSuccess({ success: 'Group created successfully.', groups: group }));
    } else if (createGroupResponse.response.status === 400) {
      yield put(apiError('Already exists another group with that name.'));
    } else if (createGroupResponse.response.status === 406) {
      yield put(apiError('File problem: Verify if data is correct.'));
    } else {
      yield put(apiError('There was an error in our end, please try again later.'));
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get Products List per merchant
 * @param {*} payload - payment details
 */
function* getProductsPerMerchant({ mid, token }) {
  const getProducts = yield call(get, {
    url: `/api/Product/GetProductPerMerchant?merchantId=${mid}`,
    token,
  });

  const products = getProducts.json.filter(product => product.isProduct);
  const services = getProducts.json.filter(product => !product.isProduct);

  if (getProducts.response.status === 200) {
    yield put(getProductsPerMerchantSuccess({ products, services }));
  } else if (getProducts.response.status === 204) {
    return;
  } else {
    yield put(apiError('There was an error retrieving the list, please reload.'));
  }
  return;
}

/**
 * Create new product
 * @param {*} payload - new product data
 */
function* createNewProduct({ payload: values }) {
  try {
    const auth = getAuthData();
    const { token, mid } = auth;

    const { description, price, shipping, name, stock, file, isProduct } = values;

    const data = new FormData();
    data.append('description', description);
    data.append('images', file.file);
    data.append('name', name);
    data.append('price', price);
    data.append('shipping', shipping);
    data.append('stock', stock);
    data.append('isProduct', isProduct);

    const createProduct = yield call(postForm, {
      url: `/api/Product/CreateProduct`,
      data,
      token,
      // json: true,
    });

    if (createProduct.response.status === 200) {
      yield put(
        createNewProductSuccess(
          isProduct ? `Product added successfully.` : 'Service added successfully.'
        )
      );
      yield getProductsPerMerchant({ mid, token });
    } else if (createProduct.response.status === 401) {
      yield put(apiError('Session has expire. Please logout and login again.'));
    } else {
      yield put(apiError('There was an error, please try again later.'));
    }
  } catch (error) {}
}

/**
 * Update product
 * @param {*} payload - product to update data
 */
function* updateProduct({ payload: data }) {
  try {
    const auth = getAuthData();
    const { token, mid } = auth;

    const {
      description,
      price,
      shipping,
      name,
      stock,
      file,
      productId,
      isActive,
      isProduct,
    } = data;

    const formData = new FormData();

    formData.append('stock', stock);
    formData.append('shipping', shipping);
    formData.append('price', price);
    formData.append('name', name);
    formData.append('isActive', isActive);
    formData.append('description', description);
    file && formData.append('images', file.file);

    const updateProduct = yield call(updateForm, {
      url: `/api/Product/UpdateProduct?productId=${productId}`,
      data: formData,
      token,
      text: file ? true : false,
    });

    if (updateProduct.response.status === 200) {
      yield put(
        updateProductSuccess(
          isProduct ? `Product added successfully.` : 'Service added successfully.'
        )
      );
      yield getProductsPerMerchant({ mid, token });
    } else if (updateProduct.response.status === 401) {
      yield put(apiError('Session has expire. Please logout and login again.'));
    } else {
      yield put(apiError('There was an error on update, please try again later.'));
    }
  } catch (error) {}
}

/**
 * Start new broadcast
 * @param {*} payload - name, groupId, description(?), file, channel
 */
function* startBroadcast({ payload: { values, channels, invoice } }) {
  try {
    const { productId, groups } = values;
    if (!invoice && groups.length === 0) {
      yield put(
        apiError('At least one group is required, if there are no groups create one first')
      );
      return;
    }

    const auth = getAuthData();
    const { token } = auth;
    const time = moment().format();
    const data = new FormData();

    data.append('Email', channels[1].checked);
    data.append('ProductId', productId);
    data.append('Sms', channels[0].checked);
    data.append('Time', time);
    !invoice && data.append('WhatsApp', channels[2].checked);
    !invoice &&
      groups.forEach(group => {
        data.append('GroupsId[]', group);
      });
    invoice && data.append('ContactEmail', values.email);
    invoice && data.append('ContactPhone', values.phone);

    let startBroadcast = null;
    if (invoice) {
      startBroadcast = yield call(postForm, {
        url: '/api/Group/StartSingleBroadcast',
        data,
        token,
      });
    } else {
      startBroadcast = yield call(postForm, {
        url: '/api/Group/StartBroadcast',
        data,
        token,
      });
    }

    if (startBroadcast.response.status === 200) {
      if (invoice) yield put(startBroadcastResponse('Invoice sent successfully.'));
      else yield put(startBroadcastResponse('Broadcast sent successfully.'));
    } else {
      yield put(apiError('Oops, there was an error, please try again later.'));
    }
  } catch (error) {
    console.error('error on start broadcast: ', error);
  }
}

// WATCHER FUNCTIONS
export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerUser);
}

export function* watchValidateEmail() {
  yield takeEvery(VALIDATE_EMAIL, validateEmail);
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchCheckActiveUser() {
  yield takeEvery(RELOAD_PAGE_AUTH, checkActiveUser);
}

export function* watchSendEmailForPasswordRecovery() {
  yield takeEvery(PASSWORD_RECOVERY, sendEmailForPasswordRecovery);
}

export function* watchSetNewPassword() {
  yield takeEvery(SET_NEW_PASSWORD, setNewPassword);
}

export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser);
}

export function* watchUpdateMerchant() {
  yield takeEvery(UPDATE_MERCHANT, updateMerchant);
}

export function* watchDeletePayments() {
  yield takeEvery(EXTERNAL_ACCOUNTS, externalAccountsAction);
}

export function* watchUpdateFile() {
  yield takeEvery(UPDATE_FILE, updateFile);
}

export function* watchUpdatePicture() {
  yield takeEvery(UPDATE_PICTURE, updatePicture);
}

export function* watchStripeConfirmSetup() {
  yield takeEvery(STRIPE_CONFIRM_SETUP, stripeConfirmSetup);
}

export function* watchRequestStripePayment() {
  yield takeEvery(REQUEST_STRIPE_PAYMENT, requestStripePayment);
}

export function* watchCancelSubscription() {
  yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscription);
}

export function* watchCreateNewProduct() {
  yield takeEvery(CREATE_NEW_PRODUCT, createNewProduct);
}

export function* watchCreateNewGroup() {
  yield takeEvery(CREATE_NEW_GROUP, createNewGroup);
}

export function* watchUpdateProduct() {
  yield takeEvery(UPDATE_PRODUCT, updateProduct);
}

export function* watchStartBroadcast() {
  yield takeEvery(START_BROADCAST, startBroadcast);
}

function* authSaga() {
  yield all([
    fork(watchCancelSubscription),
    fork(watchChangePassword),
    fork(watchCheckActiveUser),
    fork(watchCreateNewGroup),
    fork(watchCreateNewProduct),
    fork(watchDeletePayments),
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchRequestStripePayment),
    fork(watchSendEmailForPasswordRecovery),
    fork(watchSetNewPassword),
    fork(watchStartBroadcast),
    fork(watchStripeConfirmSetup),
    fork(watchUpdateFile),
    fork(watchUpdateMerchant),
    fork(watchUpdatePicture),
    fork(watchUpdateProduct),
    fork(watchUpdateUser),
    fork(watchValidateEmail),
  ]);
}

export default authSaga;
