import CryptoJS from 'crypto-js';
import { APIClient } from './apiClient';
import {
  STRIPE_PAYMENT_METHOD,
  Z2B2_MERCHANT_TOKEN,
  HAS_AN_ACTIVE_SERVICE,
  Z2B2_MERCHANT_UID,
} from './constants';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const get = new APIClient().get;
const post = new APIClient().post;
const remove = new APIClient().delete;

export const encryptSecretKey = process.env.REACT_APP_ENCRYPTED_KEY;

export const addPropertyToObject = (array, propName) => {
  try {
    let newArray = [];
    array.map((item, i) => {
      item.label = `${propName}-${i}`;
      return newArray.push(item);
    });
    return newArray;
  } catch (error) {
    console.error(error);
  }
};

export const findValuesByName = (name, array) => {
  var z = Object.keys(array)
    .filter(function(k) {
      return k.indexOf(name) == 0;
    })
    .reduce(function(newData, k) {
      newData[k] = array[k];
      return newData;
    }, {});
  return z;
};

export const encryptData = dataToEncrypt => {
  const keySize = 256;
  const salt = CryptoJS.lib.WordArray.random(16);
  const key = CryptoJS.PBKDF2(encryptSecretKey, salt, {
    keySize: keySize / 32,
    iterations: 100,
  });
  const iv = CryptoJS.lib.WordArray.random(128 / 8);
  const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  const result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
  return result.replaceAll('+', ' ');
};

export const decryptData = dataToDecrypt => {
  const key = CryptoJS.enc.Utf8.parse(encryptSecretKey);
  const iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);
  const decrypted = CryptoJS.AES.decrypt(dataToDecrypt, key, { iv: iv });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const getArrayList = async ({ value, path, param }) => {
  const array = await get({ url: `/api/miscellaneous${path}?${param}=${value}` });
  return array.json;
};

export const getList = async (path, value, string) => {
  const list = await get({ url: `/api/miscellaneous${path}=${value}` });
  const listNewKeys = changeKeys(list.json, string);
  return listNewKeys;
};

export const getSelectedOption = (array, i, type) => {
  const res = array.find(item => item.value === i - 1);
  return res;
};

export const clone = obj => Object.assign({}, obj);
export const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];
  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

export const changeKeys = (array, string) => {
  try {
    if (array !== undefined || (Array.isArray(array) && array.length)) {
      var finalArray = [];
      finalArray = array.map(item => {
        item = renameKey(item, 'name', 'label');
        item = renameKey(item, string, 'value');
        return item;
      });
      return finalArray;
    }
  } catch (error) {
    console.error(error);
  }
};

export const setLocalStorage = (name, item) => {
  localStorage.setItem(name, JSON.stringify(item));
};

export const getLocalStorage = name => {
  return JSON.parse(localStorage.getItem(name));
};

export const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
};

export const jsonToFormData = data => {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
};

export const validateFileSize = (file, size = 5) => {
  if (file != null) {
    if (file.size / 1024 / 1024 > size) {
      return false;
    } else {
      return true;
    }
  }
};

// regex
export const atLeast3Characters = /^\w{3,}$/;
export const atLeast3CharactersAndSpaces = /^([A-Za-z0-9\s]+){3,}$/;
export const cardDigits = /^((4\d{3})|(5[1-5]\d{2})|(6011)|(34\d{1})|(37\d{1}))-?\s?\d{4}-?\s?\d{4}-?\s?\d{4}|3[4,7][\d\s-]{15}$/;
export const cvcRegex = /^\d{3}$/;
export const routingNumberRegex = /^[0-9]{9}$/;
export const accountNumberRegex = /^[0-9]{9,}$/;
export const onlyLetters = /([a-zA-Zs]*$){3,}/;
export const passwordRules = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const phoneNumber = /^\d{10,11}$/;
export const statementRegex = /([A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*){5,}/;
export const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const zipCode = /^\d{5}$/;

export const groupServicesByParent = array => {
  const group = array.reduce(function(r, a) {
    r[a.parent] = r[a.parent] || [];
    r[a.parent].unshift(a);
    return r;
  }, Object.create(null));
  const result = Object.values(group);
  return result;
};

export const redirectUserIfHasActiveServices = (services, history) => {
  /* 
      0 = disabled,
      1 = pending,
      2 = active,
      3 = cancelled,
    */
  const hasAnActiveService = services.some(item => item.status === 2 || item.status === 3);
  localStorage.setItem(HAS_AN_ACTIVE_SERVICE, hasAnActiveService);
  if (hasAnActiveService) history.push('/home');
  else history.push('/subscriptions');
};

export const getAuthData = () => {
  const uid = JSON.parse(localStorage.getItem(Z2B2_MERCHANT_UID));
  const token = cookies.get(Z2B2_MERCHANT_TOKEN);
  return { token, uid: uid.uid, mid: uid.mid };
};

export const createSetupIntent = async (customerId, token) => {
  const { json, response } = await get({
    url: `/api/Subscription/CreateSetupIntent?customerId=${customerId}`,
    token,
  });
  return { setupIntent: json, status: response.status };
};

export const getPaymentMethods = async (customerId, token) => {
  const { json, response } = await get({
    url: `/api/Subscription/PaymentMethods?customerId=${customerId}`,
    token,
  });
  return { paymentMethods: json, status: response.status };
};

export const getPaymentMethodId = async (paymentMethodId, customerId, token) => {
  if (!paymentMethodId) {
    const paymentMethodsFromCookies = cookies.get(STRIPE_PAYMENT_METHOD);
    const { paymentMethods } = await getPaymentMethods(customerId, token);
    const selectedMethod = await paymentMethods.filter(({ id: id1 }) =>
      paymentMethodsFromCookies.some(({ id: id2 }) => id2 === id1)
    );
    cookies.set(STRIPE_PAYMENT_METHOD, JSON.stringify(paymentMethods), {
      path: '/',
    });
    return selectedMethod[0].id;
  } else {
    return paymentMethodId;
  }
};

export const getStatus = async ([accountId, token]) => {
  try {
    const res = await get({
      url: `/api/Merchant/GetMerchantStatus?accountId=${accountId}`,
      token,
    });
    const { disabledReason, pendingVerification, errors, currentlyDue, eventuallyDue } = res.json;
    if (currentlyDue.length > 0) {
      const array = currentlyDue.map(item => ({ reason: item }));
      return { disabledReason: true, pendingVerification, errors: array };
    } else if (eventuallyDue.length > 0) {
      const eventually = eventuallyDue.map(item => ({ reason: item }));
      return { disabledReason: true, pendingVerification, errors: eventually };
    } else if (errors.length > 0) return { disabledReason: true, pendingVerification, errors };
    else return { disabledReason, pendingVerification, errors };
  } catch (error) {
    console.error(error);
  }
};

export const convertErrorsIntoArray = obj => {
  return Object.entries(obj).map(err => ({
    name: err[0].replace('Create', '').replace('.', ' '),
    error: err[1][0],
  }));
};

export function isValidValue(value) {
  if (value === undefined || value === null) return false;
  else return true;
}

export async function getCurrencies(countryId = 17) {
  return await get({ url: `/api/Miscellaneous/GetCurrencies?countryId=${countryId}` });
}

// validate card expiration
export const validateExpiration = expiration => {
  const givenDate = expiration.split('/');
  const str = `20${givenDate[1]}/${givenDate[0]}/01`;
  const date = new Date(str);
  const today = new Date();
  return date > today;
};

// parse stripe validation errors
export const parseStripeValidationErrors = errors =>
  errors.map(item => {
    if (item.reason === 'documents.bank_account_ownership_verification.files')
      return { reason: 'Bank Account Ownership Verification Failed' };
    const msg = item.reason
      .split('.')
      .map(x =>
        x.includes('owners') || x.includes('person_')
          ? 'User'
          : x.includes('company')
          ? 'Merchant'
          : x.replace('_', ' ')
      );
    return { reason: msg.slice(msg.length - 3).join(' ') };
  });

export async function getReports(token) {
  const { response, json } = await get({
    url: `/api/Reports/Get`,
    token,
  });
  // if (response.status === 200) return json;
  if (response.status === 200) {
    const amount = json
      .map(x => x.status === 'succeeded' && parseFloat(x.amount))
      .reduce((prev, curr) => prev + curr, 0);
    const net = json
      .map(x => x.status === 'succeeded' && parseFloat(x.net))
      .reduce((prev, curr) => prev + curr, 0);
    const applicationFeeAmount = json
      .map(x => x.status === 'succeeded' && parseFloat(x.applicationFeeAmount))
      .reduce((prev, curr) => prev + curr, 0);
    const total = {
      net: `$${net}`,
      amount: `$${amount}`,
      applicationFeeAmount: `$${applicationFeeAmount}`,
    };
    const payments = json.map(x => ({
      ...x,
      amount: `$${x.amount}`,
      amountCaptured: `$${x.amountCaptured}`,
      amountRefunded: `$${x.amountRefunded}`,
      applicationFeeAmount: `($${x.applicationFeeAmount})`,
      net: `$${x.net}`,
      currency: `${x.currency.toUpperCase()}`,
    }));
    return { total, payments };
  } else return { total: [], payments: [] };
}

export async function getCustomerReport(id) {
  try {
    const { token } = getAuthData();
    const { response, json } = await get({
      url: `/api/Reports/GetCustomer?customerId=${id}`,
      token,
    });
    if (response.status === 200) return json;
    else return { error: 'No data found' };
  } catch (e) {
    console.log(e);
  }
}
