import axios from 'axios';
// import config from './../config';

// default
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

/**
 * Sets the headers depending if auth is needed
 * @param {*} token
 */
const setHeaders = (token, json = true) => {
  const Accept = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const Authorization = { Authorization: 'Bearer ' + token };
  let headers =
    json && token
      ? { ...Accept, ...Authorization }
      : !json && token
      ? { ...Authorization }
      : json && !token
      ? { ...Accept }
      : !json && !token && {};
  return headers;
};

const baseUrl = axios.defaults.baseURL;

class APIClient {
  /* fetches json from server */
  get = async ({ url, token = null }) => {
    try {
      const response = await fetch(`${baseUrl}${url}`, {
        mode: 'cors',
        method: 'GET',
        headers: setHeaders(token),
        credentials: 'include',
      });
      const json = await response.json();
      return { response, json };
    } catch (error) {
      console.error('error on get method: ', error);
    }
  };

  /**
   * post given json to url
   */
  post = async ({ url, data = null, token = null, json = false }) => {
    try {
      const response = await fetch(`${baseUrl}${url}`, {
        mode: 'cors',
        method: 'POST',
        headers: setHeaders(token),
        body: JSON.stringify(data),
        credentials: 'include',
      });
      if (json) {
        const json = await response.json();
        return { response, json };
      } else {
        return { response };
      }
    } catch (error) {
      console.error(error);
    }
  };

  postForm = async ({ url, data, token = null, json = false }) => {
    try {
      const response = await fetch(`${baseUrl}${url}`, {
        mode: 'cors',
        method: 'POST',
        body: data,
        headers: setHeaders(token, false),
        credentials: 'include',
      });
      if (response.status !== 200 && json) {
        const json = await response.json();
        return { response, json };
      } else return { response };
    } catch (error) {
      console.error('error on postForm APICLIENT: ', error);
    }
  };

  /* update method with authorization */
  put = async ({ url, data = null, token = null }) => {
    try {
      const response = await fetch(`${baseUrl}${url}`, {
        mode: 'cors',
        method: 'PUT',
        headers: setHeaders(token),
        body: JSON.stringify(data),
        credentials: 'include',
      });
      return response;
    } catch (error) {
      console.error('error en update: ', error);
    }
  };

  /* Delete */
  delete = async ({ url, token = null }) => {
    try {
      const response = await fetch(`${baseUrl}${url}`, {
        mode: 'cors',
        method: 'delete',
        headers: setHeaders(token),
        credentials: 'include',
      });
      return response;
    } catch (error) {
      console.error('error on delete method: ', error);
    }
  };

  updateForm = async ({ url, data, token = null, text = true }) => {
    try {
      const response = await fetch(`${baseUrl}${url}`, {
        mode: 'cors',
        method: 'PUT',
        body: data,
        headers: setHeaders(token, false),
        credentials: 'include',
      });
      if (text) {
        const string = await response.text();
        return { response, string };
      } else {
        return { response };
      }
    } catch (error) {
      console.error('error on update form http request: ', error);
    }
  };
}

export { APIClient };
