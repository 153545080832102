import React from 'react';
import { Container, Row, Col } from 'shards-react';

const LoginLayout = ({ children }) => {
  return (
    <div className='login-layout'>
      <Container>
        <Row className='justify-content-center'>
          <Col sm={12} md={6} lg={7} xl={6}>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginLayout;
