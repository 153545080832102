import {
  AUTH_ERROR,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD,
  CLEAR_MESSAGES,
  CREATE_NEW_GROUP_SUCCESS,
  CREATE_NEW_GROUP,
  CREATE_NEW_PRODUCT_SUCCESS,
  CREATE_NEW_PRODUCT,
  DEACTIVATE_PRODUCT_SUCCESS,
  DEACTIVATE_PRODUCT,
  EXTERNAL_ACCOUNTS_SUCCESS,
  EXTERNAL_ACCOUNTS,
  GET_PRODUCTS_LIST_SUCCESS,
  LOGIN_USER_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  PASSWORD_RECOVERY,
  REGISTER_USER_ERROR,
  REGISTER_USER_SUCCESS,
  REGISTER_USER,
  RELOAD_PAGE_AUTH_SUCCESS,
  RELOAD_PAGE_AUTH,
  REQUEST_STRIPE_PAYMENT_ERROR,
  REQUEST_STRIPE_PAYMENT_SUCCESS,
  REQUEST_STRIPE_PAYMENT,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD,
  START_BROADCAST_RESPONSE,
  START_BROADCAST,
  STRIPE_CONFIRM_SETUP_SUCCESS,
  STRIPE_CONFIRM_SETUP,
  STRIPE_VALIDATION_PENDING,
  SUCCESS_MESSAGE,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE,
  UPDATE_MERCHANT_SUCCESS,
  UPDATE_MERCHANT,
  UPDATE_PICTURE_SUCCESS,
  UPDATE_PICTURE,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_USER_SUCCESS,
  UPDATE_USER,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL,
} from './constants';

export const loginUser = (email, password, history) => ({
  type: LOGIN_USER,
  payload: { email, password, history },
});
export const loginUserSuccess = ({ user, groups, reports }) => ({
  type: LOGIN_USER_SUCCESS,
  payload: { user, groups, reports },
});

export const stripeValidationPending = stripeLink => ({
  type: STRIPE_VALIDATION_PENDING,
  payload: stripeLink,
});

export const registerUser = ({ values, testing, paymentMethod }) => ({
  type: REGISTER_USER,
  payload: { values, testing, paymentMethod },
});
export const registerUserSuccess = message => ({
  type: REGISTER_USER_SUCCESS,
  payload: message,
});
export const registerUserError = ({ message, errors }) => ({
  type: REGISTER_USER_ERROR,
  payload: { message, errors },
});

export const validateEmail = token => ({
  type: VALIDATE_EMAIL,
  payload: token,
});
export const validateEmailSuccess = message => ({
  type: VALIDATE_EMAIL_SUCCESS,
  payload: message,
});

export const checkActiveUser = ({ uid, token, mid }) => ({
  type: RELOAD_PAGE_AUTH,
  payload: { uid, token, mid },
});
export const checkActiveUserSuccess = ({ user, groups, reports }) => ({
  type: RELOAD_PAGE_AUTH_SUCCESS,
  payload: { user, groups, reports },
});

// add the products per merchant to store
export const getProductsPerMerchantSuccess = ({ products, services }) => ({
  type: GET_PRODUCTS_LIST_SUCCESS,
  payload: { products, services },
});

// forget password functions
export const sendEmailForPasswordRecovery = email => ({
  type: PASSWORD_RECOVERY,
  payload: email,
});
// export const sendEmailForPasswordRecoverySuccess = (success) => ({
//   type: PASSWORD_RECOVERY_SUCCESS,
//   payload: success,
// });
export const setNewPassword = ({ uid, newPassword }) => ({
  type: SET_NEW_PASSWORD,
  payload: { uid, newPassword },
});
export const setNewPasswordSuccess = success => ({
  type: SET_NEW_PASSWORD_SUCCESS,
  payload: success,
});

// change password in profile page
export const changePassword = (oldPassword, newPassword) => ({
  type: CHANGE_PASSWORD,
  payload: { oldPassword, newPassword },
});
export const changePasswordSuccess = success => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: success,
});

export const updateFile = (file, type, accountId) => ({
  type: UPDATE_FILE,
  payload: { file, type, accountId },
});
export const updateFileSuccess = success => ({
  type: UPDATE_FILE_SUCCESS,
  payload: { success },
});

export const updatePicture = ({ file, type }) => ({
  type: UPDATE_PICTURE,
  payload: { file, type },
});
export const updatePictureSuccess = ({ path, type }) => ({
  type: UPDATE_PICTURE_SUCCESS,
  payload: { path, type },
});

// actions to update user info
export const updateUser = data => ({
  type: UPDATE_USER,
  payload: data,
});
export const updateUserSuccess = ({ success, user }) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { success, user },
});

// actions to update merchant info
export const updateMerchant = data => ({
  type: UPDATE_MERCHANT,
  payload: data,
});
export const updateMerchantSuccess = ({ success, merchant }) => ({
  type: UPDATE_MERCHANT_SUCCESS,
  payload: { success, merchant },
});

// EXTERNAL ACCOUNTS ACTIONS (PAYMENTS)
export const externalAccountsAction = ({ action, data }) => ({
  type: EXTERNAL_ACCOUNTS,
  payload: { action, data },
});
export const externalAccountsActionSuccess = ({ success, user }) => ({
  type: EXTERNAL_ACCOUNTS_SUCCESS,
  payload: { success, user },
});

// actions for payment process
export const stripeConfirmSetup = ({ stripe, elements, customerId, services, accountId }) => ({
  type: STRIPE_CONFIRM_SETUP,
  payload: { stripe, elements, customerId, services, accountId },
});
export const stripeConfirmSetupSuccess = ({
  // setup_intent,
  // setup_intent_client_secret,
  redirect_status,
}) => ({
  type: STRIPE_CONFIRM_SETUP_SUCCESS,
  payload: { /* setup_intent, setup_intent_client_secret, */ redirect_status },
});

export const requestStripePayment = ({ merchantId, customerId, paymentMethodId, accountId }) => ({
  type: REQUEST_STRIPE_PAYMENT,
  payload: { merchantId, customerId, paymentMethodId, accountId },
});
export const requestStripePaymentSuccess = ({ services, message }) => ({
  type: REQUEST_STRIPE_PAYMENT_SUCCESS,
  payload: { services, message },
});
export const requestStripePaymentError = message => ({
  type: REQUEST_STRIPE_PAYMENT_ERROR,
  payload: message,
});

export const cancelSubscription = service => ({
  type: CANCEL_SUBSCRIPTION,
  payload: { service },
});
export const cancelSubscriptionSuccess = message => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  payload: message,
});

// create new group
export const createNewGroup = ({ title, members, description, option }) => ({
  type: CREATE_NEW_GROUP,
  payload: { title, members, description, option },
});
export const createNewGroupSuccess = ({ success, groups }) => ({
  type: CREATE_NEW_GROUP_SUCCESS,
  payload: { success, groups },
});

// create a new product
export const createNewProduct = values => ({
  type: CREATE_NEW_PRODUCT,
  payload: values,
});
export const createNewProductSuccess = message => ({
  type: CREATE_NEW_PRODUCT_SUCCESS,
  payload: message,
});

// update existing product
export const updateProduct = data => ({
  type: UPDATE_PRODUCT,
  payload: data,
});
export const updateProductSuccess = message => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: message,
});

// start new broadcast
export const startBroadcast = ({ values, channels, invoice }) => ({
  type: START_BROADCAST,
  payload: { values, channels, invoice },
});
export const startBroadcastResponse = message => ({
  type: START_BROADCAST_RESPONSE,
  payload: message,
});

export const successMessage = message => ({
  type: SUCCESS_MESSAGE,
  payload: message,
});
export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
});
export const apiError = message => ({
  type: AUTH_ERROR,
  payload: message,
});

export const logoutUser = history => ({
  type: LOGOUT_USER,
  payload: { history },
});
